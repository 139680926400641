<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.registrant.list')"
    class="px-5 py-3"
  >
    <div class="d-flex align-items-center">
      <base-date-filter @changeFilter="changeDateFilter" style="flex: 0 0 75%; max-width: 75%">
      </base-date-filter>
      <base-dropdown-filter
        :items="statusFilters"
        @changeFilter="changeStatusFilter"
        style="flex: 0 0 25%; max-width: 25%"
      >
      </base-dropdown-filter>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('agentRegistration.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.email') }}
          </th>
          <th class="primary--text">
            {{ $t('general.status') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.updatedAt') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.registrant_user_uuid">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.status }}</td>
          <td>
            {{ item.updated_at ? format(new Date(item.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.referralProgram.basePath,
      statusFilters: (state) => state.referralProgram.statusFilters,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeDateFilter(val) {
      this.$emit('changeDateFilter', val);
    },
    changeStatusFilter(val) {
      this.$emit('changeStatusFilter', val);
    },
  },
};
</script>
